import { lazy } from 'react';
import AdminLayout from "./layout/Admin/index";
const AdminLogin = lazy(() => import('./pages/Admin/Login/Login'));
const ForgotPassword = lazy(() => import('./pages/Admin/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/Admin/ResetPassword'));
const ChangePassword = lazy(() => import('./pages/Admin/ChangePassword'));
const HomeScreen = lazy(() => import('./pages/Admin/Home'));
const UserScreen = lazy(() => import('./pages/Admin/User'));
const DonationsScreen = lazy(() => import('./pages/Admin/Donations'));
const UserViewDetails = lazy(() => import('./pages/Admin/UserViewDetails'));
const DonationHistory = lazy(() => import('./pages/Admin/DonationHistory'));
const ViewOverallScreen = lazy(() => import('./pages/Admin/ViewOverall'));
const Profile = lazy(() => import('./pages/Admin/Profile'));
const PageNotFound = lazy(() => import('./pages/PageNotFound'));

const routes = [
  // Admin User routes without header
  {
    path: '/',
    component: <AdminLogin />,
    authenticate: false,
    exact: true
  },
  {
    path: '/login',
    component: <AdminLogin />,
    authenticate: false,
    exact: true
  },
  {
    path: '/forgotpassword',
    component: <ForgotPassword />,
    authenticate: false,
    exact: true
  },
  {
    path: "/resetpassword",
    component: <ResetPassword />,
    authenticate: false,
    exact: true
  },
  {
    path: '/changepassword',
    component: <ChangePassword />,
    authenticate: false,
    exact: true
  },
  // Admin User routes with header
  {
    path: '/*',
    component: <AdminLayout />,
    authenticate: false,
    exact: true
  }
];

export const AdminLayoutRoute = [

  {
    path: '/home',
    component: <HomeScreen />,
    authenticate: false,
    exact: true
  },
  {
    path: '/user',
    component: <UserScreen />,
    exact: true,
    authenticate: false,
  },
  {
    path: '/donations',
    component: <DonationsScreen />,
    exact: true,
    authenticate: false,
  },
  {
    path: '/userviewdetails',
    component: <UserViewDetails />,
    exact: true,
    authenticate: false,
  },
  {
    path: '/donationhistory',
    component: <DonationHistory />,
    exact: true,
    authenticate: false,
  },
  {
    path: '/viewoverall',
    component: <ViewOverallScreen />,
    exact: true,
    authenticate: false,
  },
  {
    path: '/profile',
    component: <Profile />,
    exact: true,
    authenticate: false,
  },
  {
    path: '/*',
    component: <PageNotFound />,
    authenticate: true,
    exact: false
  }
];

export default routes;
