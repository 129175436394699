import React from "react";
import "../../pages/PageNotFound/PageNotFound.scss";
import { multilanguage } from "redux-multilanguage";

const PageNotFound = () => {
  return (
    <div className="pageNotFoundMain">
      <div>
        <img src="https://res.cloudinary.com/zudu/image/upload/v1656476282/NRG/Mobile/icon.png" />
        <div className="pageNotFoundTitle">Error 404</div>
        <div className="pageNotFoundText">Oops! It seems something went wrong...</div>
        <button className="backButton" onClick={() => { window.history.back() }}>Back to home</button>
      </div>
    </div>
  );
};

export default multilanguage(PageNotFound);
