import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { multilanguage } from 'redux-multilanguage';
import { hideToast } from '../../redux/actions/toastAction';
import { Toast } from 'react-bootstrap';

import './ToastComponent.scss';

const ToastComponent = (props) => {
    const { toast: { type, message, show, customClass } } = props;
    const [showToast, setShowToast] = useState(false);
    useEffect(() => {
        setShowToast(show);
    }, [show]);
    let toastClass;
    let iconClass = customClass;
    if (type === 'success') {
        toastClass = 'success';
        // iconClass = 'icon_approve';
    } else if (type === 'error') {
        toastClass = 'error';
        iconClass = 'icon_close_cricle';
    } else if (type === 'warning') {
        toastClass = 'warning';
    } else {
        toastClass = '';
    }
    return (
        <Toast onClose={() => {
            setShowToast(false);
            setTimeout(() => {
                props.hideToast();
            }, 1000);
        }} className={toastClass} show={showToast} delay={3000} autohide>
            <div className="tostcenter">
                <button onClick={() => {
                    setShowToast(false)
                }} className='closebtn'><i className='icon_close'></i></button>
                <div className="icon_row">
                    {toastClass == 'success' ? <img src="https://res.cloudinary.com/zudu/image/upload/v1652930234/NRG/vjw65khdw79uej9v1gdt.svg" /> : <span className="errorToast">😢</span>}

                    <div className='toastTitle'> {type === 'success' ? 'Success' : 'Error'}</div>
                </div>
                <div className='toastMassageTxt'>{message}</div>
            </div>
        </Toast>
    );
};

const mapStateToProps = (state) => {
    return {
        toast: state.toastReducer.toast
    };
};
const mapDispatchToProps = {
    hideToast
};
export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(ToastComponent));
