import { USER_INFO } from "../types.js";

const initState = {
    isLoading: false,
    logininfo: {},
};

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case USER_INFO:
            return {
                ...state,
                userinfo: action.payload
            }
        default:
    }
    return state;
};

export default authReducer