import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { AdminLayoutRoute } from "./../../routes";
import PageNotFound from '../../pages/PageNotFound/PageNotFound';

// import Header from '../Admin/Header/Header';

const AdminLayout = () => {

    const location = useLocation();
    const [isLogin, setIsLogin] = useState(true);
    useEffect(() => {
        let isLoggedIn = localStorage.getItem('access_token') ? true : false;
        setIsLogin(isLoggedIn);
    }, []);
    const authenticateRoute = () => {
        let { pathname } = location;
        let routeIndex = AdminLayoutRoute.findIndex(route => route.path === pathname);
        // let isLoggedIn = localStorage.getItem('access_token') ? true : false;
        if (pathname !== "/" && routeIndex !== -1) {
            const Component = AdminLayoutRoute[routeIndex].component;
            return isLogin ? Component : <Navigate replace to="/" />;
        } else {
            return <PageNotFound />
        }
    }

    return (
        <div className="main-page" >
            <Routes>
                {AdminLayoutRoute.map((route, index) => {
                    return <Route
                        key={index}
                        exact={route.exact}
                        path={route.path}
                        // element={route.component}
                        element={authenticateRoute()}
                    />
                })}
            </Routes>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
